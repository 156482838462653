

.ui-addtocal {
  cursor:pointer;cursor:hand;
}

ul.ui-addtocal {
  cursor:default;
  position:absolute;
}
